<template>
  <div class="home">
    <nav-bar />
    <swiper />
    <data-management />
    <intelligentAPP />
    <aboutUs />
    <news />
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import NavBar from '../components/nav'
import swiper from '../components/swiper'
import DataManagement from '../components/DataManagement'
import IntelligentAPP from '../components/IntelligentAPP'
import AboutUs from '../components/AboutUs'
import News from '../components/News'
import Footer from '../components/Footer'
export default {
  name: 'Home',
  components: {
    NavBar,
    swiper,
    DataManagement,
    IntelligentAPP,
    AboutUs,
    News,
    Footer
  }
}
</script>

<!--
 * @Description: 
 * @Author: yuany
 * @Date: 2021-03-11 21:09:01
 * @LastEditors: yuany
-->
<template>
  <div class="intelligent-app">
    <div class="container">
      <container-title
        class="wow animate__slideInRight"
        data-wow-duration="1s"
        title="服务特点"
        desc="Service characteristics"
      />
      <div class="intelligent-app-content">
        <img
          src="../assets/AD0I1-nNBhAEGAAg4NmTyQUos5m90QEwhAI49AM.png"
          alt=""
          class="wow animate__slideInRight"
          data-wow-duration="1s"
        />
        <div
          class="intelligent-app-list wow animate__slideInRight"
          data-wow-duration="1.5s"
        >
          <div
            class="intelligent-app-item"
            v-for="(item, index) in intelligentAppList"
            :key="index"
          >
            <p class="m-b-sm m-t-none">{{ item.title }}</p>
            <span>{{ item.desc }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import containerTitle from './containerTitle'
export default {
  components: {
    containerTitle
  },
  data() {
    return {
      intelligentAppList: [
        {
          title: '机动灵活',
          desc: '快速组件专属小组, 及时有效满足客户需求, 简化用工流程'
        },
        {
          title: '零风险',
          desc: '全方位承担雇主责任, 降低企业用工风险'
        },
        {
          title: '节约成本',
          desc: '"按月租赁"人员, 避免人员冗余, 降低综合用工成本'
        },
        {
          title: '优质服务',
          desc:
            '项目经理1V1服务, 提供高质量IT人才, 全方位员工管理计划, 确保员工高效工作'
        }
      ]
    }
  }
}
</script>
<style lang="less" scoped>
.intelligent-app {
  padding-bottom: 100px;
  .intelligent-app-title {
    font-weight: 500;
    margin-bottom: 70px;
    p {
      font-size: 28px;
      color: rgb(38, 38, 38);
      font-family: SourceHanSansCN-Regular;
    }
    span {
      color: #7a7b7b;
      font-size: 15px;
    }
  }
  .intelligent-app-content {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      padding-right: 160px;
      width: 350px;
    }
    .intelligent-app-list {
      .intelligent-app-item {
        text-align: left;
        font-weight: 500;
        margin-bottom: 50px;
        &:last-child {
          margin-bottom: 0;
        }
        p {
          font-size: 16px;
          color: rgb(51, 51, 51);
        }
        span {
          font-size: 14px;
          color: #999999;
        }
      }
    }
  }
}
</style>

<!--
 * @Description: swiper
 * @Author: yuany
 * @Date: 2021-03-09 09:09:45
 * @LastEditors: yuany
-->
<template>
  <swiper ref="mySwiper" class="swiper" :options="swiperOption">
    <swiper-slide class="slide-1">
      <div>
        <p>全国领先的</p>
        <p class="m-b-md">IT人力外包及解决方案提供商</p>
        <span>专业 | 诚信 | 创新 | 担当</span>
      </div>
    </swiper-slide>
    <swiper-slide class="slide-2">
      <div>
        <p>全国领先的</p>
        <p class="m-b-md">IT人力外包及解决方案提供商</p>
        <span>专业 | 诚信 | 创新 | 担当</span>
      </div>
    </swiper-slide>
    <div class="swiper-pagination" slot="pagination"></div>
    <div
      class="swiper-button-prev"
      @click="handleClickPrev()"
      slot="button-prev"
    ></div>
    <div
      class="swiper-button-next"
      @click="handleClickNext()"
      slot="button-next"
    ></div>
  </swiper>
</template>
<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'
export default {
  name: 'swiper-banner',
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      swiperOption: {
        slidesPerView: 1,
        loop: true,
        autoplay: {
          delay: 2500,
          disableOnInteraction: false
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      }
    }
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper
    }
  },
  methods: {
    handleClickPrev() {
      this.$refs.mySwiper.$swiper.slidePrev()
    },
    handleClickNext() {
      this.$refs.mySwiper.$swiper.slideNext()
    }
  },
  mounted() {
    console.log('Current Swiper instance object', this.swiper)
    // this.swiper.slideTo(3, 1000, false)
  }
}
</script>
<style lang="less">
.swiper {
  height: 500px;
  /deep/ .swiper-slide {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .slide-1 {
    background-image: url('../assets/banner1.png');
  }
  .slide-2 {
    background-image: url('../assets/banner2.png');
  }
  .slide-1,
  .slide-2 {
    background-size: 100% 100%;
    position: relative;
    div {
      position: absolute;
      right: 200px;
      top: 100px;
      color: #fff;
      p {
        font-size: 40px;
        font-family: SourceHanSansCN-Regular;
        font-weight: 700;
        margin: 0;
      }
      .m-b-md {
        margin-bottom: 20px;
      }
      span {
        font-size: 20px;
      }
    }
  }
  .slide-1 {
    p {
      color: #000;
    }
    span {
      color: rgb(102, 102, 102);
    }
  }
  .swiper-button-prev,
  .swiper-button-next {
    width: 71px;
    height: 71px;
    background: rgba(0, 0, 0, 0.1);
    color: #fff;
    border-radius: 5px;
  }
  .swiper-button-prev {
    left: 60px;
  }
  .swiper-button-next {
    right: 60px;
  }
}
</style>

<!--
 * @Description: 
 * @Author: yuany
 * @Date: 2021-03-11 21:30:42
 * @LastEditors: yuany
-->
<template>
  <div class="about-us">
    <div class="container">
      <div class="about-us-title">
        <p class="m-r-sm">关于我们</p>
        <span>About Us</span>
      </div>
      <div class="about-us-content">
        <div
          class="about-us-desc wow animate__slideInRight"
          data-wow-duration="1s"
        >
          <p class="m-t-none">
            天合聚云成立于2017年11月，总部位于四川成都，致力于为全球用户提供IT外包服务以及全方位的解决方案
          </p>
          <p class="m-t-xl">
            天合聚云是中国百余家企业的数字化转型合作伙伴
            团队平均年龄30岁，技术骨干均来自于北上广深知名IT企业
            具备丰富的企业移动化设计、部署和运营经验
          </p>
          <p class="m-t-xl" v-if="showMore">
            在中国，天合聚云为超过100多家客户提供IT软件外包、驻场开发、IT运维服务、企业信息化产品、解决方案等服务，秉承“敏捷开发，高效交付”的宗旨，帮助企业解决IT运营困局
          </p>
          <router-link to="About" v-else>MORE>></router-link>
        </div>
        <img
          src="../assets/AD0I1-nNBhAEGAAgvb6UyQUoiLX0wQEw3QM4_wI.png"
          alt=""
          class="wow animate__slideInRight"
          data-wow-duration="1.5s"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    showMore: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style lang="less" scoped>
.about-us {
  height: 564px;
  background: url('../assets/AD0I1-nNBhACGAAgq76UyQUowOC6ogUwgA84tAQ.jpg')
    no-repeat top;
  display: flex;
  align-items: flex-end;
  .container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .about-us-title {
    font-family: SourceHanSansCN-Regular;
    font-weight: 500;
    text-align: left;
    p,
    span {
      font-size: 28px;
      color: rgb(255, 255, 255);
      display: inline-block;
      margin-top: 0;
      margin-bottom: 0;
    }
    span {
      line-height: 30px;
      font-size: 14px;
      color: rgb(178, 178, 178);
    }
  }
  .about-us-content {
    display: flex;
    align-items: flex-end;
    margin-top: 75px;
    .about-us-desc {
      align-self: flex-start;
      text-align: left;
      margin-right: 150px;
      font-family: SourceHanSansCN-Regular;
      > * {
        font-size: 14px;
        color: #999999;
        font-weight: 500;
        line-height: 30px;
      }
      a {
        display: block;
        margin-top: 65px;
        color: rgb(178, 178, 178);
      }
    }
  }
}
</style>

<!--
 * @Description: 
 * @Author: yuany
 * @Date: 2021-03-11 22:00:59
 * @LastEditors: yuany
-->
<template>
  <div class="news">
    <div class="container">
      <div
        class="news-desc-list wow animate__slideInRight"
        data-wow-duration="1s"
      >
        <div
          class="news-desc-item"
          v-for="(item, index) in newsDescList"
          :key="index"
        >
          <img :src="item.img" alt="" />
          <div class="news-desc-title">
            {{ item.title }}
          </div>
        </div>
      </div>
      <container-title
        title="服务内容"
        desc="Service Area"
        class="wow animate__slideInRight"
        data-wow-duration="1s"
      />
      <div class="news-list">
        <div
          class="news-item wow animate__slideInRight"
          v-for="(item, index) in newsList"
          :key="index"
          :data-wow-duration="index * 0.3 + 1 + 's'"
        >
          <div class="b-b">
            <div class="time">
              <!-- <p>{{ item.datetime.year }}</p>
              <span>{{ item.datetime.time }}</span> -->
              <img :src="item.img" alt="" />
            </div>
            <div class="details">
              <p>{{ item.title }}</p>
              <span>
                {{ item.desc }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import containerTitle from './containerTitle'
export default {
  components: {
    containerTitle
  },
  data() {
    return {
      newsDescList: [
        {
          img: require('../assets/news-desc-1.png'),
          title: '软件开发实施'
        },
        {
          img: require('../assets/news-desc-2.png'),
          title: '软件测试'
        },
        {
          img: require('../assets/news-desc-3.png'),
          title: '业务流程外包'
        },
        {
          img: require('../assets/news-desc-4.png'),
          title: '立案项目外包'
        }
      ],
      newsList: [
        {
          title: '现场支持服务',
          img: require('../assets/news-icon-1.png'),
          datetime: {
            year: '2017',
            time: '05-24'
          },
          desc:
            '快速响应、多语种、覆盖北上广，让企业每时每刻均能享受到高品质的服务'
        },
        {
          title: 'IT咨询服务',
          img: require('../assets/news-icon-2.png'),
          datetime: {
            year: '2017',
            time: '05-24'
          },
          desc:
            '帮助企业建立企业信息战略架构，提供可预期的IT建设路线图，提高企业应对业务变化和抗风险的能力'
        },
        {
          title: '信息安全评估',
          img: require('../assets/news-icon-3.png'),
          datetime: {
            year: '2017',
            time: '05-24'
          },
          desc:
            '从资产分类、日常运维、管理机制、系统配置等方面对组织信息安全管理，安全现状进行调研'
        },
        {
          title: '企业云',
          img: require('../assets/news-icon-4.png'),
          datetime: {
            year: '2017',
            time: '05-24'
          },
          desc:
            '凭借完整的办公解决方案，助员工实现高效的移动办公和沟通，降低企业运营成本'
        }
      ]
    }
  }
}
</script>
<style lang="less" scoped>
.news {
  padding-top: 100px;
  padding-bottom: 100px;
  .news-desc-list {
    display: flex;
    justify-content: space-between;
    .news-desc-item {
      img {
        width: 240px;
        height: 160px;
      }
      .news-desc-title {
        margin-top: 10px;
        font-family: SourceHanSansCN-Regular;
        font-size: 18px;
      }
    }
  }
  .news-title {
    margin-top: 110px;
    font-weight: 500;
    margin-bottom: 55px;
    p {
      font-size: 28px;
      color: rgb(38, 38, 38);
      font-family: SourceHanSansCN-Regular;
      line-height: 36px;
    }
    span {
      color: #7a7b7b;
      font-size: 15px;
      line-height: 21px;
    }
  }
  .news-list {
    display: flex;
    flex-wrap: wrap;
    .news-item {
      flex: 0 0 50%;
      box-sizing: border-box;
      &:nth-child(odd) {
        padding-right: 20px;
      }
      &:nth-child(even) {
        padding-left: 20px;
      }

      &:nth-last-child(1),
      &:nth-last-child(2) {
        .b-b {
          padding-bottom: 0;
          &::after {
            content: none;
          }
        }
      }
      .b-b {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: left;
        padding: 30px 0;
        position: relative;
        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          border-bottom: 1px solid hsla(0, 0%, 60%, 0.15);
        }
        .time {
          padding: 10px;
          padding-right: 25px;
          color: #626971;
          font-weight: 500;
          position: relative;
          img {
            width: 80px;
          }
          p {
            font-size: 24px;
            margin: 0;
            line-height: 36px;
          }
          span {
            font-size: 14px;
            line-height: 21px;
          }
          &::after {
            content: '';
            position: absolute;
            right: 0;
            top: 10px;
            bottom: 10px;
            border-right: 1px solid hsla(0, 0%, 80%, 0.3);
          }
        }
        .details {
          padding: 10px;
          padding-left: 25px;
          p {
            line-height: 20px;
            font-size: 16px;
            color: #333;
            margin: 0;
            margin-bottom: 14px;
          }
          span {
            line-height: 26px;
            color: #878787;
            font-size: 14px;
          }
        }
      }
    }
  }
}
</style>
